import { formatNumber } from '@angular/common';
import { LanguageCode } from '@model';

export { formatNumber } from '@angular/common';

export function isOnlySpaces(value: string): boolean {
  return value.trim().length === 0;
}

export function formatPrice(
  value: number,
  language: LanguageCode,
  maxFractionDigits = 2,
): string {
  return formatNumber(value, language, `1.2-${maxFractionDigits}`);
}

export function formatEnergy(
  value: number,
  language: LanguageCode,
  fractionDigits = 3,
): string {
  return formatNumber(value, language, `1.1-${fractionDigits}`);
}

export function formatPower(
  value: number,
  language: LanguageCode,
  maxFractionDigits = 1,
): string {
  return formatNumber(value, language, `1.0-${maxFractionDigits}`);
}

export function splice(
  text: string,
  addition: string,
  position: number,
): string {
  return [text.slice(0, position), addition, text.slice(position)].join('');
}

export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
